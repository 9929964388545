import { cssBundleHref } from '@remix-run/css-bundle';
import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import stylesheet from '~/css/tailwind.css?url';
import landingImgSmall from './assets/landing-small-4x.webp';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, json, useLoaderData } from '@remix-run/react';
import ProgressBar from './components/progressBar/ProgressBar';
import SonnerToast from './components/toasts/SonnerToast';
import React from 'react';
import { Toaster } from './components/ui/toaster';
import { gaTrackingIds } from './constants/gaTrackingIds';
import { getEnvironment } from './utils/getEnvironment';
import { segmentWriteKeys } from './constants/segment';
import useLoadSegment from './analytics/useLoadSegment';
import useGAPageView from './analytics/useGAPageView';
import useSegmentPageView from './analytics/useSegmentPageView';
import useSetAnonymousId from './analytics/useSetAnonymousId';

export const links: LinksFunction = () => [
  ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
  { rel: 'stylesheet', href: stylesheet },
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap',
  },
  {
    rel: 'preload',
    href: landingImgSmall,
    as: 'image',
  },
];

export const loader = ({ request }: LoaderFunctionArgs) => {
  const { hostname } = new URL(request.url);
  const environment = getEnvironment(hostname);
  const gaTrackingId = gaTrackingIds[environment];
  const segmentWriteKey = segmentWriteKeys[environment];
  return json({ gaTrackingId, segmentWriteKey });
};

export function Layout({ children }: { children: React.ReactNode }) {
  const { gaTrackingId, segmentWriteKey } = useLoaderData<typeof loader>();
  useLoadSegment({ writeKey: segmentWriteKey });
  useGAPageView(gaTrackingId);
  useSegmentPageView();
  useSetAnonymousId();

  return (
    <html lang="en">
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <style data-fullcalendar />
        <Links />
      </head>
      <body id="app">
        {process.env.NODE_ENV === 'development' || !gaTrackingId ? null : (
          <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`} />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}
        {children}
        <ScrollRestoration />
        <Scripts />
        <ProgressBar />
        <SonnerToast />
        <Toaster />
      </body>
    </html>
  );
}

export default function App() {
  return <Outlet />;
}
