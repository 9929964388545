import { Toaster } from 'sonner';
import useMediaQueryMatches from '~/hooks/useMediaQueryMatches';
import CircleCheckmark from '../icons/CircleCheckmark';

export default function SonnerToast() {
  const isDesktop = useMediaQueryMatches('(min-width:852px)');

  return (
    <Toaster
      position="top-right"
      toastOptions={{
        unstyled: true,
        classNames: {
          toast: `flex space-x-2 justify-center items-center bg-secondaryBrown opacity-75 py-2 px-16 rounded-lg max-w-72 ${isDesktop ? 'right-0' : 'ml-auto mr-auto'}`,
          title: 'text-white font-light text-lg',
        },
      }}
      icons={{
        success: <CircleCheckmark />,
      }}
      style={{ transform: `${isDesktop ? '' : 'translateX(-16px)'}` }}
    />
  );
}
