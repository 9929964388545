import React from 'react';
import { analytics } from './useLoadSegment';

export default function useSetAnonymousId() {
  React.useEffect(() => {
    const setAnonymousId = async () => {
      const anonymousId = (await analytics.user()).anonymousId();
      const response = await fetch('/api-set-anonymous-id', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ anonymousId }),
      }).then((response) => response.json());

      console.log(`__set-anonymous-id__${response.success ? 'success' : 'failed'}`);
    };

    setAnonymousId();
  }, []);
}
