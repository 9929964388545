import { useLocation } from '@remix-run/react';
import React from 'react';
import { pageview } from '~/utils/gtags.client';

export default function useGAPageView(trackingId: string) {
  const location = useLocation();
  React.useEffect(() => {
    if (trackingId) {
      pageview(location.pathname, trackingId);
    }
  }, [location.pathname, trackingId]);
}
